<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :sort="'id'"
        :sortOrder=-1
        :itemService=itemService
        :itemFormRef=itemFormRef
        :selectFields=selectFields
        :showAddButton=false
        :showAction=true
        :showDropdown=false
    >
        <template #columns>
            <Column field="created_at" :header="$t('overview.date')" style='width:15%'>
                <template #body="slotProps">
                    {{formatDate(slotProps.data.created_at, 'dateTimeHistory')}}
                </template>
            </Column>
            <Column field="external_api_user.description" :header="$t('navigation.users')" style='width:20%'></Column>
            <Column field="function" :header="$t('overview.function')" style='width:20%'></Column>
            <Column field="additional_information" :header="$t('overview.additional_information')" style='width:60%'></Column>
        </template>

        <template #form>
            <externalLogForm ref="ItemForm" :itemData="item" />
        </template>
    </Overview>
    
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import externalLogForm from '@/components/log/ExternalLogForm.vue';
import externalLogService from '@/services/ExternalLogService';
import store from '@/store/user';

export default {
    components: {
        Overview,
        Column,
        externalLogForm
    },
    data() {
        return {
            item: {},
            itemService: externalLogService,
            itemFormRef: null,
            selectFields: [
                'id',
                'created_at',
                'class',
                'function',
                'additional_information',
                'request_body',
                'external_api_user_id',
                {"externalApiUser": [
                    "id",
                    "description"
                ]}
            ]
        }
    },
    mounted() {
        if(!store.getters.getCompanyHq){
            window.location.href = '/';
            return;
        }
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>